import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import { useCookies } from 'react-cookie'
import { Navigate } from 'react-router-dom'
import { API_URL } from 'src/config'
import axios from 'axios'
// import refreshAccessToken from './views/refresh-token/RefreshToken'
import { useNavigate } from 'react-router-dom'
import Cookies from 'js-cookie'

function AuthenticatedRoute({ children }) {
  const [cookies, removeCookie] = useCookies(['access_token'])
  const [isLoading, setIsLoading] = useState(true)
  const navigat = useNavigate()
  // const [tokenRefresh, setTokenRefresh] = useState(false)

  const validTokens = useCallback(async () => {
    if (cookies.access_token) {
      const token = cookies.access_token
      console.log('Token:', token)
      // console.log('tokenRefresh:', tokenRefresh)
      // Start token validation
      axios
        .post(
          `${API_URL}/token/verify/`,
          { token },
          {
            headers: {
              'Content-Type': 'application/json',
            },
          },
        )
        .then((response) => {
          console.log('Token verification status:', response.status)
          if (response.status === 200) {
            console.log('Token is valid')
            setIsLoading(false)
          } else {
            console.error('Token is invalid, removing it from cookies')
            removeCookie('access_token')
            Cookies.remove('access_token')
            Cookies.remove('refresh_token')
            navigat('/login')
          }
        })
        .catch((error) => {
          Cookies.remove('access_token')
          Cookies.remove('refresh_token')
          console.error('Token is invalid, removing it from cookies')
          console.error('Error:', error)
          removeCookie('access_token')
          navigat('/login')
        })
    } else {
      setIsLoading(false)
    }
  })

  useEffect(() => {
    validTokens()
  }, [cookies.access_token, removeCookie, validTokens])

  if (isLoading) {
    return <div>Loading...</div> // or replace with a loading component
  }

  return cookies.access_token ? children : <Navigate to="/login" />
}

AuthenticatedRoute.propTypes = {
  children: PropTypes.node.isRequired,
}

export default AuthenticatedRoute
